/* -------------------------------------------------------------------------------------------------
 Vendors
 ------------------------------------------------------------------------------------------------- */

import $ from 'jquery';
window.jQuery = $;
window.$ = $;

require("./can.custom");
require("jquery-validation");
require("jquery.maskedinput/src/jquery.maskedinput");
require("select2/dist/js/select2.min");
window.Swiper = require("swiper/dist/js/swiper");
require("magnific-popup/dist/jquery.magnific-popup");
require("fotorama/fotorama");
require("popper.js/dist/esm/popper.min");
require("bootstrap/js/dist/util");
require("bootstrap/js/dist/dropdown");
require("bootstrap/js/dist/collapse");
require("bootstrap/js/dist/tooltip");
window.noUiSlider = require("nouislider/distribute/nouislider");
require("jquery-bar-rating/dist/jquery.barrating.min");
window.SimpleBar = require("simplebar/dist/simplebar.min");

var APP             = window.APP || {},
    popupOptions    = {
        fixedContentPos: true,
        fixedBgPos: true,
    };

$(function() {

    // todo delete after debug
    function screenSize() {
        $('.js-debug').html($(window).width() + '<div>' + $(window).height() + '</div>');
    }

    screenSize();
    $(window).resize(screenSize);

    // pixel perfect debug
    debug({
        picture:    $('body').data('layout'),
        top:        $('body').data('top'),
        left:       $('body').data('left')
    });

    // plugins
    $('.js-lightbox-video')            	.magnificPopup($.extend({type: 'iframe'}, popupOptions));
    $('.js-phone-mask')                 .mask('+7 999 999 99 99');
    $('.js-select-2')                   .select2({width: 'element'});
    $('.js-stars-rating')               .barrating({theme: 'css-stars'});
    $('[data-toggle="tooltip"]')        .tooltip();
    $('.js-custom-scrollbar')           .each(function(i, el) {

        var $el = $(el),
            $btnUp = $($el.data('scroll-up')),
            $btnDown = $($el.data('scroll-down')),
            simpleBar = new SimpleBar(this, {autoHide: !!$(el).data('autohide')}),
            $scrollObject = $(el.SimpleBar.getScrollElement());

        $btnUp.on('click', function() {

            var sT = $scrollObject.scrollTop();

            $scrollObject.scrollTop(sT - 50);
        });

        $btnDown.on('click', function() {

            var sT = $scrollObject.scrollTop();

            $scrollObject.scrollTop(sT + 50);
        });
    });

    $('.js-values-slider').each(function() {

        let $this = $(this),
            step = parseInt($this.data('step'), 10) || 1000,
            startMin = (parseInt($this.data('start-min'), 10) > 0 ? parseInt($this.data('start-min'), 10) : parseInt($this.data('min'), 10)) || 1,
            startMax = (parseInt($this.data('start-max'), 10) > 0 ? parseInt($this.data('start-max'), 10) : parseInt($this.data('max'), 10)) || 10000,
            rangeMin = parseInt($this.data('min'), 10) || 1,
            rangeMax = parseInt($this.data('max'), 10) || 10000,
            $inputMin = $($this.data('input-min')),
            $inputMax = $($this.data('input-max'));

        if (startMin === startMax) {

            startMax += step;
        }

        if (rangeMin === rangeMax) {

            rangeMax += step;
        }

        noUiSlider.create(this, {
            start: [startMin, startMax],
            connect: true,
            step: step,
            format: {
                to: justNumber,
                from: justNumber,
            },
            range: {
                'min': rangeMin,
                'max': rangeMax,
            }
        });

        this.noUiSlider.on('update', function(values) {

            $inputMin.val(values[0]);
            $inputMax.val(values[1]);
        });

        $inputMin.on('change', function() {

            that.noUiSlider.set([
                parseInt($inputMin.val(), 10),
                parseInt($inputMax.val(), 10),
            ]);
        });

        $inputMax.on('change', function() {

            that.noUiSlider.set([
                parseInt($inputMin.val(), 10),
                parseInt($inputMax.val(), 10),
            ]);
        });
    });

    $('body').on('click', '.js-lightbox-popup', function() {

        var id      = $(this).attr('href'),
            popup   = $(id);

        $.magnificPopup.open($.extend(popupOptions, {
            items: {src: id},
            type: 'inline',
            focus: 'input[type="text"]',
            closeMarkup: '<a title="%title%" href="javascript:void(0);" class="js-popup-close popup-close no-underline"><i class="icon-close"><svg><use xlink:href="#cross"/></svg></i></a>'
        }));

        return false;
    });

    $('body').on('click', '.js-lightbox-image', function() {

        var id      = $(this).attr('href'),
            image   = $(this).data('image'),
            $popup  = $(id);

        $popup.find('.js-image').attr('src', image);

        $.magnificPopup.open($.extend(popupOptions, {
            items: {src: id},
            type: 'inline',
            focus: 'input[type="text"]',
            closeMarkup: '',
        }));

        return false;
    });

    $('body').on('click', '.js-popup-close, .mfp-close', function() { $.magnificPopup.close(); });

    // переключение мобильного меню
    $('.js-menu-toggle').click(function () {

        $(this).toggleClass('active');

        $('.js-mobile-menu').toggleClass('showed');
    });

    // controls
    $('body')                           .each(function(i, el) { new APP.Header       		($(el)); });
    $('.js-swiper')                     .each(function(i, el) { new APP.SwiperSlider 		($(el)); });
    $('.js-form-callback')              .each(function(i, el) { new APP.FormCallback        ($(el)); });
    $('.js-input-spinner')              .each(function(i, el) { new APP.InputSpinner        ($(el)); });
    $('.js-fotorama')                   .each(function(i, el) { new APP.Fotorama            ($(el)); });
    $('.js-tabs')                       .each(function(i, el) { new APP.Tabs                ($(el)); });
    $('.js-accordion')                  .each(function(i, el) { new APP.Accordion           ($(el)); });
    $('.js-navigation')                 .each(function(i, el) { new APP.Navigation          ($(el)); });
});


APP.Appearance = can.Control.extend({

    init: function() {

        this.scrollTimer    = null;
        this.elements       = $('.js-check-appear');

        $(window).on('scroll', this.proxy(this.onScroll));

        this.check();
    },

    check: function() {

        var that = this;

        this.elements.each(function() {

            that.checkElement(this);
        });
    },

    checkElement: function(element) {

        var $this = $(element);

        if (isInViewport(element)) {

            if (!$this.is('.js-in-viewport')) {

                $this.trigger('appear').addClass('js-in-viewport');
            }
        } else {

            if ($this.is('.js-in-viewport')) {

                $this.trigger('disappear').removeClass('js-in-viewport')
            }
        }
    },

    onScroll: function() {

        clearInterval(this.scrollTimer);

        this.scrollTimer = setTimeout(this.proxy(this.check), 100)
    },
});

/* -------------------------------------------------------------------------------------------------
 InputSpinner
 ------------------------------------------------------------------------------------------------- */
APP.InputSpinner = can.Control.extend({

    init: function () {

        this.input = this.element.find('.js-input');

        this.min = this.element.data('min') || 0;
        this.max = this.element.data('max') || 99;

        this.value = parseInt(this.input.val(), 10);
    },

    limits: function () {

        if (this.value < this.min) {

            this.value = this.min;
        }

        if (this.value > this.max) {

            this.value = this.max;
        }
    },

    set: function (needToTriggerEvent) {

        needToTriggerEvent = needToTriggerEvent || false;

        this.input.val(this.value);

        if (needToTriggerEvent) {

            this.input
                .trigger('keyup')
                .trigger('change');
        }
    },

    '.js-btn-minus click': function () {

        this.value--;

        this.limits();
        this.set(true);
    },

    '.js-btn-plus click': function () {

        this.value++;

        this.limits();
        this.set(true);
    },

    '.js-input change': function () {

        this.value = parseInt(this.input.val(), 10);

        this.limits();
        this.set();
    },
});

/* -------------------------------------------------------------------------------------------------
 Аккордион
 ------------------------------------------------------------------------------------------------- */
APP.Accordion = can.Control.extend({

    init: function () {

        this.classOpened    = 'js-accordion-opened'
        this.link           = this.element.find('.js-accordion-toggle').first();
        this.content        = this.element.find('.js-accordion-content').first();
        this.opened         = this.element.is('.' + this.classOpened);

        this.opened ? this.content.css({'display': 'block'}) : this.content.css({'display': 'none'});

        this.link.on('click', this.proxy(this.toggleBox));
    },

    toggleBox: function(obj) {

        this.opened ? this.content.slideUp() : this.content.slideDown();
        this.opened ? this.element.removeClass(this.classOpened) : this.element.addClass(this.classOpened);

        this.opened = !this.opened;
    }
});

/* -------------------------------------------------------------------------------------------------
 Мобильный фильтр
 ------------------------------------------------------------------------------------------------- */
APP.Navigation = can.Control.extend({

    init: function () {

        this.$rootUl = this.element.find('.js-root');
        this.$currentUl = null;
        this.$backLink = this.element.find('.js-back');
        this.$header = this.element.find('.js-header');
        this.$resetLink = this.element.find('.js-reset');

        console.log('navigation inited');
    },

    '.js-parent click': function(el) {

        var $this = $(el),
            $li = $this.closest('li'),
            $ul = $li.find('ul');

        if ($ul.length > 0) {

            $li.siblings().find('.js-parent').show();
            $li.siblings().not($li).hide();

            $this.hide();
            $ul.show();

            this.$currentUl = $ul;
            this.$backLink.show();
            this.$header.hide();
            this.$resetLink.hide();
        }

        console.log($(this));
    },

    '.js-reset click': function() {

        console.log('reset');
    },

    '.js-back click': function() {

        this.$currentUl.hide();
        this.$currentUl = null;

        this.$rootUl.find('li').show();
        this.$rootUl.find('li > a').show();

        this.$backLink.hide();
        this.$header.show();
        this.$resetLink.show();
    },
});

/* -------------------------------------------------------------------------------------------------
 Fotorama
 ------------------------------------------------------------------------------------------------- */
APP.Fotorama = can.Control.extend({

    init: function () {

        this.thumbheight = 159;
        this.thumbwidth = 187;
        this.elementWidthBreakpoint = 580;

        this.element.fotorama({
            thumbheight: this.thumbheight,
            thumbwidth: this.thumbwidth,
            ratio: 600/383,
            width: '100%',
            nav: 'thumbs',
            fit: 'cover',
            arrows: 'always',
            autoplay: 3000,
            thumbmargin: 15,
        });

        this.fotorama = this.element.data('fotorama');

        $(window).on('resize', this.proxy(this.resize));

        this.resize();
    },

    resize: function() {

        this.fotorama.setOptions({
            thumbheight: this.getThumbHeight(),
            thumbwidth: this.getThumbWidth(),
        });
    },

    getThumbHeight: function() {

        var eW = this.element.width();

        if (eW < this.elementWidthBreakpoint) {

            return (eW - 30) / 3 * (this.thumbheight / this.thumbwidth);
        } else {

            return this.thumbheight;
        }
    },

    getThumbWidth: function() {

        var eW = this.element.width();

        return eW < this.elementWidthBreakpoint ? (eW - 20) / 3 : this.thumbwidth;
    },
});

/* -------------------------------------------------------------------------------------------------
 Переключение шапки
 ------------------------------------------------------------------------------------------------- */
APP.Header = can.Control.extend({

    init: function() {

        this.floatHeader    = this.element.find('.js-float-header');
        this.breakthrough   = 300;
        this.className      = 'header--floating';
        this.timer          = null;

        this.check();
    },

    '{window} scroll': function() {

        clearInterval(this.timer);
        this.timer = setTimeout(this.proxy(this.check), 0);
    },

    check: function() {

        var top = parseInt($(document).scrollTop(), 10);

        top > this.breakthrough || window.sectionNumber > 1 ? this.floatHeader.addClass(this.className) : this.floatHeader.removeClass(this.className);
    },
});

/* -------------------------------------------------------------------------------------------------
 Карусель
 ------------------------------------------------------------------------------------------------- */
APP.SwiperSlider = can.Control.extend({
    defaults: {
        paginationClickable: 			true,
        autoplayDisableOnInteraction: 	false,
        pagination:                     '.swiper-pagination',
        autoHeight:                     true,
    }
},{
    init: function() {

        this.disableWidth   = this.element.data('disable-width') || false;
        this.userInteracted = false;
        this.interactTimer  = null;
        this.nextButton     = this.element.data('next-button') ? $(this.element.data('next-button')) : this.element.find('.swiper-button-next');
        this.prevButton     = this.element.data('prev-button') ? $(this.element.data('prev-button')) : this.element.find('.swiper-button-prev');
        this.params         = $.extend(this.options, {
            autoplay:               parseInt(this.element.data('autospeed'), 10) || false,
            navigation: {
                nextEl: this.nextButton,
                prevEl: this.prevButton,
            },
            pagination: {
                el: this.element.data('pagination') || this.element.find('.swiper-pagination'),
                type: 'bullets',
            },
            scrollbar: {
                el: '.swiper-scrollbar',
            },
            breakpoints:            this.element.data('breakpoints') || null,
            spaceBetween:           this.element.data('space-between') || 0,
            slidesPerView:          this.element.data('slides-per-view') || 1,
            slidesPerColumn:        this.element.data('slides-per-column') || 1,
            slidesPerGroup:         this.element.data('slides-per-group') || 1,
            centeredSlides:         this.element.data('centered-slides') || false,
            loop:                   typeof this.element.data('loop') != 'undefined' ? !!this.element.data('loop') : true,
            loopAdditionalSlides:   typeof this.element.data('loop') != 'undefined' ? !!this.element.data('loop') : 1,
            freeMode:               typeof this.element.data('free-mode') != 'undefined' ? !!this.element.data('free-mode') : false,
        });
//d(this.params);

        this.enableSwiper();
        this.mediaQuery();
    },

    enableSwiper: function() {

        this.swiper = new Swiper(this.element, this.params);
    },

    mediaQuery: function() {

        if (!this.disableWidth) {

            return;
        }

        var that                = this,
            breakpoint          = window.matchMedia( '(max-width:' + this.disableWidth + 'px)' ),
            breakpointChecker   = function() {

            // if larger viewport and multi-row layout needed
            if ( breakpoint.matches === true ) {

                // clean up old instances and inline styles when available
                if ( that.swiper !== undefined ) {

                    that.swiper.destroy( true, true );
                }

                return;

            } else if ( breakpoint.matches === false ) {

                return that.enableSwiper();
            }
        };

        breakpoint.addListener(breakpointChecker);

        // kickstart
        breakpointChecker();
    }
});

/* -------------------------------------------------------------------------------------------------
 Настройки валидатора
 ------------------------------------------------------------------------------------------------- */
$.validator.addMethod('rightEmail', function(val, el) {return (/^([a-zA-Z0-9_\-]+\.)*[a-zA-Z0-9_\-]+@([a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9]\.)+[a-zA-Z]{2,4}$/i.test(val));}, 'Введите корректный e-mail');
$.validator.addMethod('rightPhone', function(val, el) {return (/((\+7) ?)?\(?\d{3}\)? ?\d{3} ?\d{2} ?\d{2}$/i.test(val));}, 'Введите корректный телефон');

$.validator.messages.minlength  = 'Не менее {0} символов';
$.validator.messages.maxlength  = 'Не более {0} символов';
$.validator.messages.required   = 'Нужно заполнить';


/* -------------------------------------------------------------------------------------------------
 Абстрактная форма
 ------------------------------------------------------------------------------------------------- */
APP.Form = can.Control.extend({

    init: function() {

        this.form          = this.element.find('form');
        this.loader        = this.element.find('.js-loader');
        this.formHolder    = this.element.find('.js-form-holder');
        this.successHolder = this.element.find('.js-success');
        this.errorHolder   = this.element.find('.js-error');
        this.busy          = false;
        this.validator     = this.validation();

        this.ie = (window.FormData === undefined);
    },

    validation: function() {

        return this.form.validate({
            submitHandler: this.proxy(this.submit)
        });
    },

    submit: function() {

        if (this.busy == false) {

            this.busy = true;

            $.ajax({
                method:         'post',
                url:            this.form.attr('action'),
                data:           this.getParams(),
                beforeSend:     this.proxy(this.showLoader),
                complete:       this.proxy(this.hideLoader),
                success:        this.proxy(this.success),
                error:          this.proxy(this.error),
                dataType:       'json'
            });
        }

        return false;
    },

    getParams: function() {

        return this.form.serializeObject();
    },

    success: function(response) {

        this.busy = false;

        if (response.errors != undefined) {

            if ($.isPlainObject(response.errors)) {

                this.validator.showErrors(response.errors);
            } else {

                this.element.find('.js-error-message').html(response.errors);
                this.errorHolder.show();
                this.formHolder.hide();
            }

        } else {

            this.clearForm();

            this.formHolder.hide();
            this.successHolder.show();
            this.afterSuccess(response);
        }
    },

    error: function(errors) {
console.dir(errors);
        this.formHolder.hide();
        this.errorHolder.show();
    },

    showLoader: function() {

        this.loader.show();
        this.successHolder.hide();
        this.errorHolder.hide();
        this.element.find('input').attr('disabled','disabled');
    },

    hideLoader: function() {

        this.busy = false;
        this.loader.hide();
        this.element.find('input').removeAttr('disabled');
    },

    clearForm: function() {

        this.element.find('input:text, textarea').each(this.proxy(function(i, el) {

            $(el).val('');
        }));

        this.element.find('input:checkbox').each(this.proxy(function(i, el) {

            if ($(el).is(':checked')) {

                $(el).click();
            }
        }));
    },

    showForm: function() {

        this.formHolder.show();
        this.form.find('input:text').first().focus();

        this.successHolder.hide();
        this.errorHolder.hide();
    },

    afterSuccess: function(response) {},

    '.js-submit click': function() {

        this.form.submit();
    },

    '.js-show-form click': function() {

        this.showForm();
    }
});

/* -------------------------------------------------------------------------------------------------
 Форма обратного звонка
 ------------------------------------------------------------------------------------------------- */
APP.FormCallback = APP.Form.extend({

    validation: function() {

        return this.form.validate({
            rules: {
                email: {
                    required: true,
                    rightEmail: true,
                },
                phone: {
                    required: true,
                    rightPhone: true
                },
                ch1: {
                    required: true,
                },
            },
            submitHandler: this.proxy(this.submit)
        });
    },

    afterSuccess: function(response) {

        this.element.trigger('form.sent');

        $.magnificPopup.open($.extend({
            items: {
                src: '#success'
            },
            type: 'inline'
        }, popupOptions));

        this.showForm();
    }
});

/* -------------------------------------------------------------------------------------------------
 Табы
 ------------------------------------------------------------------------------------------------- */
APP.Tabs = can.Control.extend({

    init: function() {

        this.tabContHolder  = this.element.find('.js-tab-content-holder');
        this.tabContent     = this.element.find('.js-tab-content');
        this.tabLinks       = this.element.find('.js-tab');
        this.activeTab      = this.element.data('active-tab') || 0;

        var hash            = window.location.hash.substr(1) || null,
            $hashTab        = hash ? this.element.find('.js-tab-content[data-tab="' + hash + '"]') : null,
            $activeTab      = $hashTab != null && $hashTab.length > 0 ? $hashTab : this.element.find('.js-tab-content[data-tab="' + this.activeTab + '"]'),
            $activeTabLink  = hash ? this.tabLinks.filter('[data-tab-id="' + hash + '"]') : this.element.find('.js-tab[data-tab-id="' + this.activeTab + '"]');

        this.tabContent.hide();
        $activeTab.show();

        this.tabLinks.removeClass('active')
        $activeTabLink.addClass('active');

        this.element.addClass('inited');
    },

    '.js-tab click': function(obj) {

        var $obj                = $(obj),
            index               = $obj.data('tab-id'),
            $activeTabContent   = this.element.find('.js-tab-content[data-tab=' + index + ']'),
            height              = $activeTabContent.data('height');

        this.element.find('.js-tab').removeClass('active');
        this.element.find('.js-tab[data-tab-id=' + index + ']').addClass('active');

        /*this.tabContHolder
            .stop(false, false)
            .animate({height: height}, 500);

        this.tabContent.not($activeTabContent).fadeOut();
        $activeTabContent.fadeIn();*/

        this.tabContent.not($activeTabContent).hide();
        $activeTabContent.show();

        window.location.hash = index;

        return false;
    }
});

/* -------------------------------------------------------------------------------------------------
 Helpers
 ------------------------------------------------------------------------------------------------- */

// пауза
function sleep(duration, callback) {

	return setTimeout(callback, duration);
}

// parents
jQuery.expr[':'].parents = function(a,i,m){
    return jQuery(a).parents(m[3]).length < 1;
};

// сериализация в объект
$.fn.serializeObject = function() {
    var o = {};
    var a = this.serializeArray();
    $.each(a, function() {
        if (o[this.name]) {
            if (!o[this.name].push) {
                o[this.name] = [o[this.name]];
            }
            o[this.name].push(this.value || '');
        } else {
            o[this.name] = this.value || '';
        }
    });
    return o;
};

// плавно скроллируем к нужному элементу
function scrollToElement(selector, callback, delta) {

    var destination = $(selector).offset();

    if (destination) {

        var top = (destination.top - 30 > 0) ? (destination.top - 30) : 0;

        delta = parseInt(delta, 10) || 0;

        top += delta;

        callback = callback || function() {};

        $('body, html').animate({'scrollTop': top}, {'duration': 1500, 'complete': function() {callback();}});
    }
}

/**
 * Аналог функции empty в php
 *
 * @param mixed_var
 * @returns {boolean}
 */
function empty(mixed_var) {

    let key, i, len;
    const emptyValues = [undefined, null, false, 0, '', '0'];

    for (i = 0, len = emptyValues.length; i < len; i++) {

        if (mixed_var === emptyValues[i]) {

            return true;
        }
    }

    if (typeof mixed_var === 'object') {

        for (key in mixed_var) {

            return false;
        }

        return true;
    }

    return false;
}

/**
 * Форматирует число для вывода как цену
 *
 * @param number
 * @returns {*|String}
 */
function price(number) {

    return new Intl.NumberFormat().format(number);
}

/**
 * Определяет является ли элемент в зоне видимости
 *
 * @param elem
 * @returns {boolean}
 */
function isInViewport(elem) {

    var bounding = elem.getBoundingClientRect();

    return (
        bounding.top >= 0 &&
        bounding.left >= 0 &&
        bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
};

/**
 * Debug var
 *
 * @param $var
 */
function d($var) {

    console.log($var);
}

/**
 *
 * @param value
 * @returns {number}
 */
function justNumber(value) {

    return parseInt(value, 10);
}

/* -------------------------------------------------------------------------------------------------
 @Debug
 ------------------------------------------------------------------------------------------------- */
let debug = function (options) {

    $('body').prepend('<div class="jsDebug"></div>');

    let defaults = {
            top: -66,
            left: -39,
            zIndex: 100000,
            opacity: 50,
            picture: null,
            maxZIndex: 100000
        },
        debug = $('.jsDebug'),
        opacity = defaults.opacity / 100;

    options = $.extend(defaults, options)

    debug.css({
        position: 'absolute',
        top: parseInt(options.top),
        left: parseInt(options.left),
        //width: '500px',
        width: '100%',
        height: '2500%',
        zIndex: options.zIndex,
        textAlign: 'center',
        opacity: opacity,
        display: 'none',
        overflow: 'hidden',
    });

    debug.append('<img src="' + options.picture + '" alt="">');

    $(document).on('keydown', function (ev) {

        switch (ev.keyCode) {
            case 48:
                toggleDebug();
                break; // *
            case 106:
                toggleDebug();
                break; //
            case 107:
                increaseOpacity();
                break; //
            case 109:
                decreaseOpacity();
                break; //
            case 111:
                toggleZIndex();
                break; //
        }
    });

    let toggleDebug = function () {
        debug.toggle();
    };

    let decreaseOpacity = function () {
        
        if (options.opacity > 10) {
            options.opacity = options.opacity - 10;
        }

        let opacity = options.opacity / 100;

        debug.css({
            opacity: opacity
        })
    };

    let increaseOpacity = function () {
        
        if (options.opacity < 100) {
            options.opacity = options.opacity + 10;
        }

        let opacity = options.opacity / 100;
        
        debug.css({
            opacity: opacity
        })
    };

    let toggleZIndex = function () {

        let zIndex = debug.css('z-index');

        if (zIndex == 0) {
            debug.css('z-index', options.maxZIndex);
        } else {
            debug.css('z-index', 0);
        }
    }
}; // */